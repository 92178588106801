import React from 'react';
// import facebookIcon from '../assets/icons/facebook.svg';
import twitterIcon from '../Icons/Socials/twitter.png';
import githubIcon from '../Icons/Socials/github.png';
import linkedinIcon from '../Icons/Socials/linkedin.png';
// import voorivexIcon from '../Icons/Socials/voorivex.png'; 
// import blogIcon from '../Icons/Socials/blog1.png'; 
// import sptifyIcon from './Icons/120x120px/Spotify.png'; 
// import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'; // Import social media icons

const About = () => {
  return (

    <div className="max-w-2xl mx-auto py-10 px-4 press-start-2p-regular">
      <h1 className="text-3xl font-bold mb-4">About Me</h1>
      <p className="text-lg mb-1">
        I’m omid, part-time bug bounty hunter, loves exploring web and mobile application security and playing video games in my free time.<br /><br />You can follow me here …
      </p>
      <div className="flex space-x-0">
        <a href="https://x.com/omidxrz" target="_blank" rel="noopener noreferrer">
          <img src={twitterIcon} alt="Twitter" style={{ width: '50px', height: '50px' }} className="hover:opacity-90" />
        </a>
        <a href="https://github.com/omidxrz" target="_blank" rel="noopener noreferrer">
          <img src={githubIcon} alt="Github" style={{ width: '50px', height: '50px' }} className="hover:opacity-90" />
        </a>
        <a href="https://www.linkedin.com/in/omidxrz" target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt="Linkedin" style={{ width: '50px', height: '50px' }} className="hover:opacity-90 " />
        </a>
      </div>
    </div>
  );
};

export default About;