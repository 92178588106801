// import React from "react";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";


const articles = [
  {
    id: 4,
    title: "A Weird CSP Bypass led to $3.5k Bounty",
    content: "Roughly 5 months ago, YShahinzadeh and I found an XSS vulnerability that had a weird CSP bypass leading to Account Takeover and received a $3500 bounty. The journey was quite interesting to me as it involved deep recon, reading many documents of the website, and facing a CSP bypass I had never seen before, so let's begin the writeup.",
    slug: "https://blog.voorivex.team/a-weird-csp-bypass-led-to-35k-bounty",
    thumbnail: "https://cdn.hashnode.com/res/hashnode/image/upload/v1729712527577/38febc46-1781-495b-afe7-860270563754.webp?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    datePublished: "2024-10-23",
  },{
    id: 3,
    title: "Hijacking OAuth Code via Reverse Proxy ",
    content: "The target scope I had selected was fixed to the main application:",
    slug: "https://blog.voorivex.team/hijacking-oauth-code-via-reverse-proxy-for-account-takeover",
    thumbnail: "https://cdn.hashnode.com/res/hashnode/image/upload/v1700243293419/8c4b7106-432c-4552-9c17-69b2c7ef16e6.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    datePublished: "2023-11-17",
  },{
    id: 2,
    title: "$9240 Bounty in 30 days Hunt Challenge",
    content: "Today, I'm excited to share my journey as a full-time bug bounty hunter over the past three months. I'll discuss disappointments, challenges, notable events, and, of course, the successes that have shaped this exhilarating adventure.",
    slug: "https://blog.voorivex.team/9240-bounty-in-30-days-hunt-challenge",
    thumbnail: "https://cdn.hashnode.com/res/hashnode/image/upload/v1697901496831/c75e0fbd-14d2-4039-b375-8eab7d905cd3.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    datePublished: "2023-10-21",
  },
  {
    id: 1,
    title: "Uncovering a Command Injection, $2400 Bounty",
    content: "Hello, in this write-up I will explain how I found four P1 and two P2 bugs and showed multiple attack scenarios.",
    slug: "https://blog.voorivex.team/uncovering-a-command-injection-2400-bounty",
    thumbnail: "https://cdn.hashnode.com/res/hashnode/image/upload/v1697308156397/8066834e-7029-4e1c-b402-bbe342d5a297.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    datePublished: "2023-10-14",
  },
  // Additional articles...
];

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

const ArticleList = () => {
  const [charLimit, setCharLimit] = useState(100);
  useEffect(() => {
    const updateCharLimit = () => {
      const width = window.innerWidth;
      if (width < 640) {
        setCharLimit(0); // Small screens
      } else if (width < 1024) {
        setCharLimit(100); // Medium screens
      } else {
        setCharLimit(150); // Large screens
      }
    };

    updateCharLimit();
    window.addEventListener("resize", updateCharLimit);
    return () => window.removeEventListener("resize", updateCharLimit);
  }, []);
  return (
    <div className="space-y-8 prose dark:prose-invert">
      {articles.map(article => {
        const isExternal = article.slug.startsWith("http");

        return (
          <article
            key={article.id}
            className="flex items-start space-y-0 md:space-y-0 space-x-4"
          >
            {isExternal ? (
              <a 
                href={article.slug} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="block flex-shrink-0"
              >
                <img 
                  src={article.thumbnail} 
                  alt={article.title} 
                  className="w-24 h-24 object-cover rounded-lg shadow-lg"
                />
              </a>
            ) : (
              <Link to={`/articles/${article.slug}`} className="block flex-shrink-0">
                <img 
                  src={article.thumbnail} 
                  alt={article.title} 
                  className="w-24 h-24 object-cover rounded-lg shadow-lg"
                />
              </Link>
            )}
            <div className="flex-grow">
              {isExternal ? (
                <a href={article.slug} target="_blank" rel="noopener noreferrer">
                  <h2 className="text-xl font-bold">{article.title}</h2>
                </a>
              ) : (
                <Link to={`/articles/${article.slug}`}>
                  <h2 className="text-xl font-bold">{article.title}</h2>
                </Link>
              )}
              <p className="text-sm text-gray-500 poppins-medium">
               {formatDate(article.datePublished)}
              </p>
              <p >
              {article.content.length > charLimit ? `${article.content.slice(0, charLimit)}` : article.content}
              </p>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default ArticleList;